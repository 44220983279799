import { Person, PersonThingRange } from '@eagle/core-data-types';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useHref } from 'react-router-dom';
import { useCustomRoutes } from '../../hooks';
import { CacheDataTypes } from '../../types';
import { testid } from '../../util';
import { FetchOne } from '../fetch';

interface Props {
  'data-testid'?: string;
  variant?: Variant;
  data: PersonThingRange[];
  sx?: SxProps;
}

export const ThingPersonList: FC<Props> = ({ data, variant, sx, ...props }): JSX.Element => {
  const { person: personRoute } = useCustomRoutes();
  const href = useHref(`/${personRoute}`);

  if (!data.length) return <></>;

  return (
    <Stack data-testid={props['data-testid']} direction="row" alignItems='center' sx={sx}>
      {data.map((thingPerson, i: number) =>
        <Box key={i}>
          {i !== 0 && <Typography component='span' color="text.primary" variant={variant}>{' , '}</Typography>}
          <FetchOne
            dataType={CacheDataTypes.PERSON}
            id={thingPerson.personId}
            renderFactory={(person: Person) => (
              <Link
                data-testid={testid`thing-map-popup-person-${person._id}`}
                href={`${href}/${person._id}`}
                sx={{ cursor: 'pointer' }}
                variant={variant}
              >
                {person.display}
              </Link>
            )}
          />
        </Box>,
      )}
    </Stack>
  );
};
