/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { MarkerType } from '@eagle/api-types';
import { ThingLastLocation } from '@eagle/core-data-types';
import { EthingsRestClient } from '@eagle/ethings-rest-client';
import { Box, useTheme } from '@mui/material';
import L, { DivIcon, divIcon, DomEvent } from 'leaflet';
import { debounce, isNil, max } from 'lodash';
import { DateTime } from 'luxon';
import { createContext, Dispatch, FC, ReactElement, ReactNode, RefObject, SetStateAction, useContext, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useMap } from 'react-leaflet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FilterTypes } from '../components';
import { AppliedFilter } from '../components/entity-search';
import { ToggleLayers } from '../components/map/layer-selection/layer-selection.types';
import { BBox, BoundingBox, ZOOM } from '../components/map/page-map.types';
import { MapDiscoverItem } from '../components/search-thing-map/search-map.types';
import { CLUSTER_BOUNDS_PADDING, MAP_FLY_TO_DURATION, MAP_RELOAD_TIME_HOURS, SIDEBAR_WIDTH } from '../constants';
import { useCustomRoutes } from '../hooks';
import markerShadow from '../img/marker-shadow.png';
import { makeStyles } from '../theme';
import { Maybe, Nullable, Undefinable } from '../types';
import { filterToQuery, ReplacePath } from './filter';
import { trackEvent } from './google-analytics';
import { getLocalStorageItem, setLocalStorageItem, useLocalStorage } from './local-storage';

const DEFAULT_PIN_SIZE: L.PointExpression = [30, 30];
const PADDING_X = 10;
const PADDING_Y = 100;
const POPUP_OFFSET = 50;
const MIN_MAP_WIDTH_TO_OMIT_BOUNDS = 90;
const ICON_SIZES: Record<MarkerType, [number, number]> = {
  [MarkerType.DOT]: [27, 27],
  [MarkerType.PIN]: [25, 38],
};

type DimmingByLayer = Partial<Record<ToggleLayers | 'things', boolean>>;

interface EntityMap {
  handleMarkerClick: (id: string) => void;
  mapDimming: boolean;
  onAddressSelected: (address: MapDiscoverItem, map: L.Map) => void;
  onGeofenceSelected: (geofenceCoords: L.LatLng[], map: L.Map) => void;
  onItemSelected: (id: string, position: MapPosition) => void;
  popupSize: PopupSize;
  savedSearchPosition: Nullable<MapPosition>;
  setMapDimming: (layer: keyof DimmingByLayer, dimmed: boolean) => void;
  setPopupSize: (size: PopupSize) => void;
  setSavedSearchPosition: (position: Nullable<MapPosition>) => void;
  setSelectedThingLocation: Dispatch<SetStateAction<Undefinable<ThingLastLocation>>>;
  selectedThingLocation: Undefinable<ThingLastLocation>;
}

export enum MapStorageKeys {
  GEOFENCE = 'GEOFENCE',
  HISTORY_JOURNEY_SAVED_POSITION = 'HISTORY_JOURNEY_SAVED_POSITION',
  HISTORY_MAP_SAVED_POSITION = 'HISTORY_MAP_SAVED_POSITION',
  HISTORY_SNAP_TO_ROAD_PREFERENCE = 'HISTORY_SNAP_TO_ROAD_PREFERENCE',
  MAP_BASE_LAYER = 'MAP_BASE_LAYER',
  MAP_TOGGLE_LAYERS = 'MAP_TOGGLE_LAYERS',
  POINT_OF_INTEREST = 'POINT_OF_INTEREST',
  OPERATOR_MAP = 'OPERATOR_MAP_POSITION',
  THING_MAP = 'THING_MAP_POSITION',
  THING_SEARCH_MAP = 'THING_SEARCH_MAP_POSITION',
}

export enum MapPanes {
  BREADCRUMBS = 'breadcrumbs',
  CLUSTER_LINE = 'clusterLine',
  HOVER_LINE = 'hoverLine',
  POPUP_PANE = 'popupPane',
  START_END_MARKERS = 'startEndMarkers',
}

export interface MapPosition {
  alt?: number;
  lat: number;
  lng: number;
  timeStamp: number;
}

export type SavedMapPosition = MapPosition | Record<string, Nullable<MapPosition>>

export interface PopupSize {
  height: number;
  width: number;
}

export const mapContext = createContext<Undefinable<EntityMap>>(undefined);

export const MapProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { thingId } = useParams();
  const { things } = useCustomRoutes();
  const [mapDimmingByLayer, setMapDimmingByLayer] = useState<DimmingByLayer>({});
  const [popupSize, setPopupSize] = useState<PopupSize>({ height: 0, width: 0 });
  const [savedSearchPosition, setSavedSearchPosition] = useLocalStorage<Nullable<MapPosition>>(MapStorageKeys.THING_SEARCH_MAP, null);
  const [selectedThingLocation, setSelectedThingLocation] = useState<Undefinable<ThingLastLocation>>();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMarkerClick = (id: string): void => {
    if (id !== thingId) {
      navigate(`/map/${things}/${id}`, {
        replace: false,
        state: {
          previousLocations: undefined,
          shouldFly: false,
        },
      });
      return;
    }
    navigate(`/map/${things}`, {
      state: {
        shouldFly: false,
      },
    });
  };

  const onItemSelected = (id: string, position: MapPosition): void => {
    navigate(`/map/${things}/${id}`, {
      replace: id === thingId,
      state: {
        previousLocations: location,
        shouldFly: true,
      },
    });
    if (thingId && id !== thingId) return;
    setSavedSearchPosition(position);
  };

  const onAddressSelected = (address: MapDiscoverItem, map: L.Map): void => {
    if (!address) return;
    const zoom = getZoomFromArea(address.resultType);
    map.setView(address.position, zoom, { animate: true, duration: MAP_FLY_TO_DURATION });
    trackEvent('keyword_search', 'selected_location', 'thing_map', { 'location': address.title });
  };

  const onGeofenceSelected = (geofenceCoords: L.LatLng[], map: L.Map): void => {
    const mapCoords = L.latLngBounds(geofenceCoords).pad(CLUSTER_BOUNDS_PADDING);
    map.flyToBounds(mapCoords, { animate: true, duration: MAP_FLY_TO_DURATION });
    trackEvent('keyword_search', 'selected_geofence', 'thing_map');
  };

  const setMapDimming = (layer: keyof DimmingByLayer, value: boolean): void => {
    setMapDimmingByLayer((values) => ({ ...values, [layer]: value }));
  };

  useEffect(() => {
    setMapDimming('things', !!thingId);
  }, [thingId]);

  return (
    <mapContext.Provider
      value={{
        handleMarkerClick,
        mapDimming: Object.values(mapDimmingByLayer).includes(true),
        onAddressSelected,
        onGeofenceSelected,
        onItemSelected,
        popupSize,
        savedSearchPosition,
        setMapDimming,
        setPopupSize,
        setSavedSearchPosition,
        setSelectedThingLocation,
        selectedThingLocation,
      }}
    >
      {children}
    </mapContext.Provider>
  );
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export const useMapContext = function (): EntityMap {
  const data = useContext(mapContext);
  if (!data) throw new Error('Missing MapProvider in tree above useMapContext');
  return data;
};

export const getIcon = (pin: string | ReactElement, pinScale = 1, pinLabelPosition: L.Direction = 'right', config?: L.DivIconOptions): L.Icon | L.DivIcon => {
  const pinSize: L.PointExpression = [pinScale * DEFAULT_PIN_SIZE[0], pinScale * DEFAULT_PIN_SIZE[1]];

  const getToolTipAnchor = (): L.PointExpression => {
    switch (pinLabelPosition) {
      case 'top':
        return [0, -pinSize[1]];
      case 'bottom':
        return [0, 0];
      case 'left':
        return [-pinSize[0] / 2, -pinSize[1] / 2];
      case 'right':
        return [pinSize[0] / 2, -pinSize[1] / 2];
      default:
        return [0, 0];
    }
  };

  const iconAnchor: L.PointExpression = [pinSize[0] / 2, pinSize[1]];
  const popupAnchor: L.PointExpression = [0, -pinSize[1]];

  const markerConfig = {
    iconSize: pinSize,
    popupAnchor,
    tooltipAnchor: getToolTipAnchor(),
    iconAnchor,
    ...config,
  };

  if (typeof pin === 'string') {
    return L.icon({
      ...markerConfig,
      iconUrl: pin,
    });
  }

  return L.divIcon({
    ...markerConfig,
    html: renderToStaticMarkup(<Box
      sx={{
        '& .MuiSvgIcon-root': {
          width: '100%',
          height: '100%',
        },
      }}>{pin}</Box>),
  });
};

export const ValidateResize: FC<{ delay?: number }> = ({ delay = 1 }): JSX.Element => {
  const map = useMap();

  useEffect(() => {
    const onResize = debounce(() => map.invalidateSize(), delay);
    const observer = new ResizeObserver(onResize);

    document
      .querySelectorAll('.map-resize-listener')
      .forEach((node: Element) => observer.observe(node));

    return () => {
      observer.disconnect();
      onResize.cancel();
    };
  }, [delay, map]);
  return <></>;
};

export const disableClickPropagation = (ref: RefObject<HTMLDivElement | HTMLButtonElement>): void => {
  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.setProperty('z-index', '801');
    DomEvent.disableClickPropagation(ref.current);
  }, [ref]);
};

interface BoundingBoxResponse {
  bounds: L.LatLngBounds;
  east: number;
  height: number;
  isInBounds: (degrees: number) => boolean;
  locationInBounds: (location: L.LatLng) => boolean;
  north: number;
  south: number;
  toHereMapsIn: () => string;
  toString: () => string;
  west: number;
  width: number;
}

export const getZoomFromArea = (area: string): number => {
  return ZOOM[area] ?? ZOOM.street;
};

export const getBoundingBox = (map: L.Map, offset = 0): BoundingBoxResponse => {
  const bounds = map.getBounds().pad(offset);

  const west = (bounds.getWest() - offset) > -180 ? (bounds.getWest() - offset) : -180;
  const south = bounds.getSouth() - offset;
  const east = (bounds.getEast() + offset) < 180 ? (bounds.getEast() + offset) : 180;
  const north = bounds.getNorth() + offset;

  const width = Math.abs(west - east);
  const height = Math.abs(south - north);

  const locationInBounds = (location: L.LatLng): boolean => {
    if (location.lat < north && location.lat > south && location.lng > west && location.lng < east) {
      return true;
    }
    return false;
  };

  return {
    bounds,
    west,
    south,
    east,
    north,
    width,
    height,
    isInBounds: (degrees) => height <= degrees && width <= degrees,
    locationInBounds,
    toString: () => `${west},${south},${east},${north}`,
    toHereMapsIn: () => `bbox:${west},${south},${east},${north}`,
  };
};

export const updateBounds = (previous: BoundingBox, current: BoundingBox): BoundingBox => {
  if (previous.east < current.east
    || previous.north < current.north
    || previous.south > current.south
    || previous.west > current.west
  ) {
    return {
      east: current.east,
      north: current.north,
      south: current.south,
      west: current.west,
    };
  }
  return previous;
};

export const wrapLongitude = (longitude: number): number => (longitude % 360 + 540) % 360 - 180;

export const savedPositionCheck = (storageKey: string): void => {
  const storageCheck = getLocalStorageItem<Nullable<SavedMapPosition>>(storageKey);
  if (!storageCheck) return;
  if (isMapPosition(storageCheck)) {
    if (!storageCheck.lat || !storageCheck.lng) return localStorage.removeItem(storageKey);
    if (!storageCheck.timeStamp) setLocalStorageItem(storageKey, { ...storageCheck, timeStamp: DateTime.now().toMillis() });
    return;
  }
  for (const [accountId, value] of Object.entries(storageCheck)) {
    if (value && DateTime.now().diff(DateTime.fromMillis(value.timeStamp), 'hours').hours > MAP_RELOAD_TIME_HOURS) {
      delete storageCheck[accountId];
    }
  }
  setLocalStorageItem(storageKey, storageCheck);
};

export const isMapPosition = (savedPosition: SavedMapPosition): savedPosition is MapPosition => {
  return !isNil(savedPosition.lat) && !isNil(savedPosition.lng);
};

export const generatePointsSpiral = (count: number, centerPt: L.Point): L.Point[] => {
  const spiderfyDistanceMultiplier = 2;
  let angle = 0;
  let legLength = spiderfyDistanceMultiplier * 11;
  const separation = spiderfyDistanceMultiplier * 28;
  const lengthFactor = spiderfyDistanceMultiplier * 5 * (Math.PI * 2);

  const res: L.Point[] = [];

  for (let i = count; i >= 0; i--) {
    if (i < count) {
      res[i] = new L.Point(centerPt.x + legLength * Math.cos(angle), centerPt.y + legLength * Math.sin(angle)).round();
    }
    angle += separation / legLength + i * 0.0005;
    legLength += lengthFactor / angle;
  }

  return res;
};

export const generatePointsCircle = (count: number, centerPt: L.Point): L.Point[] => {
  if (count > 8) return generatePointsSpiral(count, centerPt);
  const circumference = 50 * (count + 4);
  const legLength = circumference / (Math.PI * 2);
  const angleStep = (Math.PI * 2) / count;
  const res: L.Point[] = [];

  res.length = count;

  for (let i = 0; i < count; i++) {
    const angle = 0 + i * angleStep;
    res[i] = new L.Point(centerPt.x + legLength * Math.cos(angle), centerPt.y + legLength * Math.sin(angle)).round();
  }

  return res;
};

const coordinateValidator = (bound: number, coord: Maybe<number>): boolean => {
  const absBound = Math.abs(bound);
  return coord !== null && coord !== undefined && isFinite(coord) && !isNaN(coord) && coord >= -1 * absBound && coord <= absBound;
};

export const isValidThingLocation = (location: Maybe<ThingLastLocation>): boolean => {
  return location !== null && location !== undefined && coordinateValidator(90, location.latitude) && coordinateValidator(180, location.longitude);
};

export const isValidNumber = (num: unknown): num is number => {
  return typeof num === 'number' && isFinite(num) && !isNaN(num);
};

export const asNumberOrUndefined = (num: unknown): number | undefined => {
  return isValidNumber(num) ? num : undefined;
};

export const getPanByPixels = (
  markerPoint: L.Point,
  popupSize: PopupSize,
  paddingX = PADDING_X,
  paddingY = PADDING_Y,
  popupOffset = POPUP_OFFSET,
): { panXby: number; panYby: number } => {
  const { x, y } = markerPoint;

  const maxRequiredSpaceX = popupSize.width / 2 + paddingX;
  const maxRequiredSpaceY = popupSize.height + popupOffset + paddingY;
  const windowWidthWithoutSidebar = window.innerWidth - SIDEBAR_WIDTH;

  const leftTolerance = x < maxRequiredSpaceX;
  const rightTolerance = x > windowWidthWithoutSidebar - maxRequiredSpaceX;
  const topTolerance = y < maxRequiredSpaceY;
  const panLeftAmount = x - maxRequiredSpaceX;
  const panRightAmount = x + maxRequiredSpaceX - windowWidthWithoutSidebar;
  const panTopAmount = y - maxRequiredSpaceY;

  if (leftTolerance && topTolerance) return { panXby: panLeftAmount, panYby: panTopAmount };
  if (rightTolerance && topTolerance) return { panXby: panRightAmount, panYby: panTopAmount };
  if (leftTolerance) return { panXby: panLeftAmount, panYby: 0 };
  if (topTolerance) return { panXby: 0, panYby: panTopAmount };
  if (rightTolerance) return { panXby: panRightAmount, panYby: 0 };

  return { panXby: 0, panYby: 0 };
};

export const toBBox = (bounds: BoundingBox): BBox => [bounds.west, bounds.south, bounds.east, bounds.north];

export const wrapLongIfNecessary = (long: number, centerLong: number | undefined): number =>
  centerLong && (long - centerLong) > 180 ? long - 360 : long;

export const getMarkerTemplate = (type?: MarkerType, icon?: React.ReactNode, image?: Nullable<string>, background?: Nullable<string>, iconColor = 'currentcolor', indicator?: Undefinable<string>, markerStroke = 'transparent', iconSize?: [number, number]): DivIcon => {
  const theme = useTheme();

  const markerBackground = background ?? theme.marker.background;

  let markup;

  const { classes } = makeStyles()(() => ({
    pin: {
      '&:before': {
        display: indicator ? 'block' : 'none',
        position: 'absolute',
        top: '-1px',
        right: '-1px',
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '4px',
        background: indicator,
        zIndex: 2,
      },
      '&:after': {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        content: '""',
        width: '44px',
        height: '44px',
        background: `url("${markerShadow}")`,
        zIndex: -1,
      },
      '& .icon': {
        color: iconColor,
        position: 'absolute',
        top: '4px',
        left: '50%',
        transform: 'translateX(-50%) translate(0.5px, -3.5px) scale(85%)',
        lineHeight: 1,
        zIndex: 1,
      },
    },
    dot: {
      '&:before': {
        display: indicator ? 'block' : 'none',
        position: 'absolute',
        top: '0px',
        right: '0px',
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '4px',
        background: indicator,
        zIndex: 2,
      },
      '& .dot-image': {
        position: 'relative',
        width: theme.marker.dot.size,
        height: theme.marker.dot.size,
        borderRadius: '50%',
        background: markerBackground,
        backgroundImage: image ? `url(${image})` : '',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        boxShadow: theme.shadows[2],
        borderColor: markerStroke,
        borderWidth: '2px',
        borderStyle: 'solid',
        '& .inner-circle': {
          width: '10px',
          height: '10px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          borderRadius: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff',
        },
      },
      '& .icon': {
        color: iconColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '1em',
        width: '1em',
        lineHeight: 1,
        zIndex: 1,
        fontSize: theme.marker.icon.size,
      },
    },
  }))();

  if (type === MarkerType.DOT) {
    markup = `
    <div data-chromatic="ignore" class="${classes.dot}">
      <div class="icon">${(!image && icon) ? renderToStaticMarkup(<>{icon}</>) : ''}</div>
      <div class="dot-image">${(!image && !icon) ? '<div class="inner-circle"></div>' : ''}</div>
    </div>`;
  } else {
    markup = `
    <div data-chromatic="ignore" class="${classes.pin}">
      <div class="icon">${(!image && icon) ? renderToStaticMarkup(<>{icon}</>) : ''}</div>
      <svg width="25" height="36" stroke-width='${markerStroke ? '2' : '0'}' stroke-color='${markerStroke}' viewBox="0 0 25 36" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M25 12.6154C25 22 12.5 36 12.5 36C12.5 36 0 21 0 12.6154C0 5.6481 5.59644 0 12.5 0C19.4036 0 25 5.6481 25 12.6154Z" fill="${markerBackground}" />
        <mask id="mask0_6_2" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="21" height="21">
          <circle cx="12.5" cy="12.5" r="10.5" fill="white" />
        </mask>
        ${image ? `<g mask="url(#mask0_6_2)">
          <image x="5" y="4" width="16" height="16" xlink:href="${image}"/>
        </g>` : ''}
        ${!image && !icon ? '<circle cx="12.5" cy="12.5" r="5.5" fill="white" />' : ''}
      </svg>
    </div>
    `;
  }

  const iconDimensions = iconSize || ICON_SIZES[type ?? MarkerType.PIN];

  return divIcon({
    html: markup,
    iconSize: iconDimensions,
    iconAnchor: [iconDimensions[0] / 2, type === MarkerType.DOT ? iconDimensions[1] / 2 : iconDimensions[1]],
    tooltipAnchor: [0, iconDimensions[1] / -2],
  });
};

export const getThingLocations = (
  tmpBounds: L.LatLngBounds,
  restClient: EthingsRestClient,
  locationsMap: Nullable<Map<string, ThingLastLocation>>,
  handleLoadingError: () => void,
  setLocationsMap: (value: SetStateAction<Nullable<Map<string, ThingLastLocation>>>) => void,
  setLastUpdate: (value: SetStateAction<DateTime>) => void,
  setMaxUpdated: (value: SetStateAction<Date | undefined>) => void,
  setLoading: ((loading: boolean) => void) | undefined,
  setLocationLoading: ((loading: boolean) => void) | undefined,
  updated?: Date,
  tmpFilters?: AppliedFilter[],
  isRefresh?: boolean,
): void => {
  const southEast = tmpBounds.getSouthEast().wrap();
  const northWest = tmpBounds.getNorthWest().wrap();

  // Defining this at module level causes undefined errors on load
  const filterReplacePaths: ReplacePath[] = [
    {
      old: FilterTypes.ID,
      new: FilterTypes.THING,
    },
  ];

  const width = tmpBounds.getEast() - tmpBounds.getWest();
  const parameterBounds = width < MIN_MAP_WIDTH_TO_OMIT_BOUNDS
    ? {
      bottomRightLat: southEast.lat,
      bottomRightLong: southEast.lng,
      topLeftLat: northWest.lat,
      topLeftLong: northWest.lng,
    }
    : {};

  setLocationLoading?.(true);
  restClient.location.getForThing({
    ...parameterBounds,
    thingFilter: filterToQuery(tmpFilters ?? [], filterReplacePaths),
    updated: isRefresh ? updated : undefined,
  }).then((data) => {
    // filter items from data whose location is valid and use those for further operations
    const filteredItems = data.items.filter((x) => isValidThingLocation(x));

    if (isRefresh) {
      const newMap = new Map(locationsMap);
      filteredItems.forEach((item) => {
        newMap.set(item.thingId, item);
      });
      setLocationsMap(newMap);
    } else {
      setLocationsMap(new Map(filteredItems.map((item) => [item.thingId, item])));
    }
    setLastUpdate(DateTime.now());
    setMaxUpdated(max(filteredItems.map((item) => DateTime.fromISO(item.updated.toISOString())).filter((x) => x.isValid))?.toJSDate());
  })
    .catch(() => {
      handleLoadingError?.();
    })
    .finally(() => {
      setLoading?.(false);
      setLocationLoading?.(false);
    });
};

export const areObjectsEqual = (obj1: BoundingBox, obj2: BoundingBox): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const areBoundsInside = (boundsToCheck: BoundingBox, boundsToCheckAgainst: BoundingBox): boolean => {
  return boundsToCheck.north <= boundsToCheckAgainst.north
    && boundsToCheck.south >= boundsToCheckAgainst.south
    && boundsToCheck.west >= boundsToCheckAgainst.west
    && boundsToCheck.east <= boundsToCheckAgainst.east;
};

const getMapBoundingBoxSize = (map: L.Map): { width: number; height: number } => {
  const boundingBox = getBoundingBox(map);
  return {
    width: boundingBox.width,
    height: boundingBox.height,
  };
};

export const useSubscribeToMapDimensions = (): { width: number; height: number; zoomLevel: number } => {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());
  const [size, setSize] = useState(() => getMapBoundingBoxSize(map));

  useEffect(() => {
    const onChange = (): void => {
      setZoomLevel(map.getZoom());
      setSize(getMapBoundingBoxSize(map));
    };

    map.on('zoomend', onChange);
    map.on('resize', onChange);

    return () => {
      map.off('zoomend', onChange);
      map.off('resize', onChange);
    };
  }, [map]);

  return { zoomLevel, ...size };
};

export const roundLatLng = (latLng: L.LatLng, decimals: number): L.LatLng => {
  const factor = Math.pow(10, decimals);

  const lat = Math.round(latLng.lat * factor) / factor;
  const lng = Math.round(latLng.lng * factor) / factor;

  return new L.LatLng(lat, lng);
};
