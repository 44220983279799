import { FC } from 'react';
import { DisplayWithDeleted } from '../../types';
import { FetchOne } from '../fetch';
import { SimpleElementSearch } from '../simple-element';

interface Props {
  'data-testid'?: string;
  dataType: string;
  id: unknown;
}

export const FetchOneColumn: FC<Props> = ({ dataType, id, ...props }) => {
  return <FetchOne
    id={id}
    dataType={dataType}
    renderFactory={(data: DisplayWithDeleted) => <SimpleElementSearch data-testid={props['data-testid']} data={data} />}
  />;
};
