import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import ClassicLayerImage from '../../../img/classic-layer.jpg';
import ParcelBoundariesImage from '../../../img/parcel-boundaries-layer.jpg';
import SatelliteLayerImage from '../../../img/satellite-layer.jpg';
import { GeofenceController } from '../layers';
import ClassicLayer from '../layers/classic-layer';
import { classicMapStyle } from '../layers/classic-vector-map-style';
import { IncidentLayer } from '../layers/incident-layer';
import ParcelBoundaries from '../layers/parcel-boundaries';
import { parcelBoundariesStyles } from '../layers/parcel-boundaries-map-style';
import { PointOfInterestController } from '../layers/point-of-interest';
import SatelliteLayer from '../layers/satellite-layer';
import { getSatelliteMapStyle } from '../layers/satellite-vector-map-style';
import { TrafficLayer } from '../layers/traffic-layer';
import { TransportLayer } from '../layers/transport-layer';
import { BaseLayers, MapLayerImage, MapLayerToggle, ToggleLayers } from './layer-selection.types';

const TEMP_VECTOR_LAYER_FLAG = 'track-overlay-vector-layer-on-satellite-map-temporary-feature';

const toggleLayerFlags: Record<ToggleLayers, string> = {
  [ToggleLayers.traffic]: 'portals-traffic-map-layer',
  [ToggleLayers.transport]: 'portals-transport-map-layer',
  [ToggleLayers.incidents]: 'portals-incidents-map-layer',
  [ToggleLayers.petrolStations]: 'portals-petrol-stations-map-layer',
  [ToggleLayers.geofences]: 'portals-geofences-map-layer',
  [ToggleLayers.pointOfInterest]: 'track-point-of-interest-feature',
};

export const toggleLayers: (flags: LDFlagSet) => MapLayerToggle[] = (flags) => {
  const layers = [
    {
      label: ToggleLayers.traffic,
      layerComponent: <TrafficLayer />,
      minZoomLevel: 6.5,
    },
    {
      label: ToggleLayers.transport,
      minZoomLevel: 7.5,
      layerComponent: <TransportLayer />,
    },
    {
      label: ToggleLayers.incidents,
      layerComponent: <IncidentLayer />,
      maxBoundingBoxSize: 1,
    },
    {
      label: ToggleLayers.petrolStations,
      layerComponent: <></>,
    },
    {
      label: ToggleLayers.geofences,
      layerComponent: <GeofenceController />,
      showSubMenuButton: true,
    },
    {
      label: ToggleLayers.pointOfInterest,
      layerComponent: <PointOfInterestController />,
      showSubMenuButton: true,
    },
  ];
  return layers.filter((layer) => !toggleLayerFlags[layer.label] || flags[toggleLayerFlags[layer.label]]);
};

const baseLayerFlags: Record<BaseLayers, string> = {
  [BaseLayers.satellite]: 'portals-satellite-map-layer',
  [BaseLayers.classic]: 'portals-classic-map-layer',
  [BaseLayers.parcelBoundaries]: 'portals-parcel-boundaries-map-layer',
};

export const baseLayers: (flags?: LDFlagSet) => MapLayerImage[] = (flags) => {
  const layers = [
    {
      image: <img height="auto" src={SatelliteLayerImage} style={{ top: 0, left: 0, position: 'absolute' }} width="100%" />,
      label: BaseLayers.satellite,
      layerComponent: <SatelliteLayer />,
      mapStyle: getSatelliteMapStyle(flags && !!flags[TEMP_VECTOR_LAYER_FLAG]),
    },
    {
      image: <img height="auto" src={ClassicLayerImage} style={{ top: 0, left: 0, position: 'absolute' }} width="100%" />,
      label: BaseLayers.classic,
      layerComponent: <ClassicLayer />,
      mapStyle: classicMapStyle,
    },
    {
      image: <img height="auto" src={ParcelBoundariesImage} style={{ top: 0, left: 0, position: 'absolute' }} width="100%" />,
      label: BaseLayers.parcelBoundaries,
      layerComponent: <ParcelBoundaries />,
      mapStyle: parcelBoundariesStyles,
    },
  ];

  if (flags) return layers.filter((layer) => !baseLayerFlags[layer.label] || flags[baseLayerFlags[layer.label]]);
  return layers;
};
