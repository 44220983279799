import { Box, Link, Stack, styled, Typography } from '@mui/material';
import chroma from 'chroma-js';
import { FC } from 'react';
import { DataCardProps } from './data-card.types';

interface ContainerProps {
  wrap?: boolean;
}

const Container = styled(Box)<ContainerProps>(({ theme, wrap }) => ({
  position: 'relative',
  alignItems: 'center',
  borderRadius: theme.dataCard.borderRadius,
  background: theme.dataCard.background,
  color: theme.dataCard.contrastText,
  width: '100%',
  padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
  '& .avatar': {
    width: '60px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      fontSize: theme.dataCard.avatarSize,
      maxWidth: '1em',
      maxHeight: '1em',
    },
    '& svg': {
      fontSize: '2rem',
    },
  },
  '& .indicator': {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  '& .content': {
    minWidth: 0,
    width: '100%',
  },
  '& .label, .title, .hint': {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: wrap ? 'normal' : 'nowrap',
  },
  '& a': {
    textDecoration: 'none',
  },
  '& .label, .hint': {
    color: chroma(theme.dataCard.contrastText).alpha(0.7).hex(),
  },
}));

export const DataCard: FC<DataCardProps> = ({ avatar, indicator, label, title, hint, href, wrap, ...props }) => {
  return <Container wrap={wrap} data-testid={props['data-testid']}>
    {indicator ? <Box className='indicator'>{indicator}</Box> : <></>}
    <Stack alignItems='center' direction='row' gap={1} height='100%' >
      {avatar ? <Box className='avatar'>{avatar}</Box> : <></>}
      <Box className='content'>
        <Stack direction='column'>
          {label ? <Typography data-testid={props['data-testid'] ? `${props['data-testid']}-label` : ''} className='label' component='div' variant='dataCardLabel'>{label}</Typography> : <></>}
          {title ? <Typography data-testid={props['data-testid'] ? `${props['data-testid']}-title` : ''} className='title' component='div' variant='dataCardTitle'>{href ? <Link component='a' href={href}>{title}</Link> : title}</Typography> : <></>}
          {hint ? <Typography data-testid={props['data-testid'] ? `${props['data-testid']}-hint` : ''} className='hint' component='div' variant='dataCardHint'>{hint}</Typography> : <></>}
        </Stack>
      </Box>
    </Stack>
  </Container>;
};
