import { Person, PersonType } from '@eagle/core-data-types';
import { Avatar, Link, styled } from '@mui/material';
import { FC } from 'react';
import { useHref } from 'react-router-dom';
import { useCustomRoutes } from '../../../hooks';
import { CacheDataTypes } from '../../../types';
import { FetchOne, FetchOneOfAll } from '../../fetch';
import { Icon } from '../../icons/widget-icon';
import { DataCard } from '../data-card';

interface Props {
  'data-testid'?: string;
  id?: string;
  person?: Person;
  personType?: PersonType;
  disableLink?: boolean;
  wrap?: boolean;
}

const TransparentAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.dataCard.contrastText,
}));

const DefaultAvatar: FC = () => {
  return <TransparentAvatar><Icon icon='PersonIcon' /></TransparentAvatar>;
};

const PersonTypeDisplay: FC<{ id: string }> = ({ id }) => {
  return <FetchOneOfAll dataType={CacheDataTypes.PERSON_TYPE} id={id} notFoundFactory={() => <></>} renderFactory={(personType: PersonType) => {
    return <>{personType.display}</>;
  }} />;
};

const PersonTypeAvatar: FC<{ personType: PersonType }> = ({ personType }) => {
  if (personType.image?.filename) {
    return <TransparentAvatar src={personType.image.filename} />;
  }

  if (personType.icon) {
    return <TransparentAvatar><Icon icon={personType.icon} /></TransparentAvatar>;
  }

  return <DefaultAvatar />;
};

const PersonAvatar: FC<{ person: Person; personType?: PersonType }> = ({ person, personType }) => {
  if (person.image?.filename) {
    return <TransparentAvatar src={person.image.filename} />;
  }

  if (person.icon) {
    return <TransparentAvatar><Icon icon={person.icon} /></TransparentAvatar>;
  }

  if (personType) {
    return <PersonTypeAvatar personType={personType} />;
  }

  return <FetchOneOfAll dataType={CacheDataTypes.PERSON_TYPE} id={person.personTypeId} notFoundFactory={() => <DefaultAvatar />} renderFactory={(personType: PersonType) => {
    return <PersonTypeAvatar personType={personType} />;
  }} />;
};

const PersonLink: FC<{ title: string; id: string }> = ({ title, id }) => {
  const { person: personRoute } = useCustomRoutes();
  const href = useHref(`/${personRoute}/${id}`);
  return <Link href={href}>{title}</Link>;
};

export const PersonDataCard: FC<Props> = ({ id, person, personType, disableLink, wrap, ...props }) => {
  if (id) {
    return <FetchOne
      dataType={CacheDataTypes.PERSON}
      id={id}
      loadingFactory={() => <DefaultAvatar />}
      renderFactory={(person: Person) => {
        return <DataCard data-testid={props['data-testid']} wrap={wrap} title={!disableLink ? <PersonLink id={id} title={person.display} /> : person.display} label={personType ? <>{personType.display}</> : <PersonTypeDisplay id={person.personTypeId} />} avatar={<PersonAvatar person={person} personType={personType} />} />;
      }}
    />;
  }

  if (person) {
    return <DataCard data-testid={props['data-testid']} wrap={wrap} title={!disableLink ? <PersonLink id={person._id} title={person.display} /> : person.display} label={personType ? <>{personType.display}</> : <PersonTypeDisplay id={person.personTypeId} />} avatar={<PersonAvatar person={person} personType={personType} />} />;
  }

  return <></>;
};
