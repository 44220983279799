import { Thing, ThingType } from '@eagle/core-data-types';
import { FC } from 'react';
import { useOptionalSearch } from '../../../pages';
import { CacheDataTypes } from '../../../types';
import { FetchOne, FetchOneOfAll } from '../../fetch';
import { FormatProperty } from '../../format';
import { BasicColumn } from '../basic-column';

interface Props {
  'data-testid'?: string;
  id?: string;
  thing?: Thing;
  property: string;
  shared?: boolean;
}

const Column: FC<{ thing: Thing; property: string; shared?: boolean }> = ({ thing, property, shared }) => {
  const propertyType = shared ? 'sharedProperties' : 'properties';
  const searchContext = useOptionalSearch();

  return <FetchOneOfAll dataType={CacheDataTypes.THING_TYPE} id={thing.thingTypeId} notFoundFactory={() => <></>} renderFactory={(thingType: ThingType) => {
    const definition = thingType[propertyType].definitions[property];
    const value = thing[propertyType][property];

    if (!definition) {
      return <></>;
    }

    return (
      <BasicColumn label={definition.label} type="property">
        <FormatProperty value={value || null} definition={definition} highlightText={searchContext?.text} />
      </BasicColumn>
    );
  }} />;
};

export const ThingPropertyColumn: FC<Props> = ({ id, thing, property, shared, ...props }) => {
  if ((!id && !thing) || !property) {
    return <></>;
  }

  if (id) {
    return <FetchOne
      dataType={CacheDataTypes.THING}
      id={id}
      renderFactory={(thing: Thing) => {
        return <Column data-testid={props['data-testid']} thing={thing} property={property} shared={shared} />;
      }}
    />;
  }

  if (thing) {
    return <Column data-testid={props['data-testid']} thing={thing} property={property} shared={shared} />;
  }

  return <></>;
};
