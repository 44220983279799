import { RoleFunction } from '@eagle/common';
import { Person, PersonThingRangeResponse, SharedThing, SharedThingType, Thing, ThingType } from '@eagle/core-data-types';
import { Alert, Button, Card, CardContent, Divider, Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { useFetchOneCache, usePromise } from '../../hooks';
import { CacheDataTypes, Portals, PortalTypes } from '../../types';
import { useHasAuthorization } from '../../util';
import { BasicEntityCard } from '../basic-card/basic-card';
import { EntityCardProps, EntityEditableCard } from '../basic-card/types';
import { ErrorMessage } from '../error-message';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { useBoolFlag } from '../flags';
import { LabelsEditor } from '../labels-editor';
import { LoadingButton } from '../loading-button';
import { MiddleSpinner } from '../middle-spinner';
import { PersonThingDetail } from '../person-card/person-thing-detail';
import { PortalSwitchingDropdown } from '../portal-switching';
import { PropertiesElement, PropertiesElementProps } from '../properties-element';
import { TagsEditor } from '../tags-editor';
import { TextEditor } from '../text-editor';

const VIEW_ROLES = [RoleFunction.USER] as const;
const THING_ADMINISTRATOR = [RoleFunction.THING_ADMINISTRATOR] as const;

const NormalCard: FC<EntityCardProps<Thing, ThingType, SharedThingType>> = ({ entity, entityType, portal, sharedEntityType }) => {
  if (entityType && (sharedEntityType || !entityType.sharedThingTypeId)) {
    return (
      <BasicEntityCard
        entity={entity}
        entityType={entityType}
        portal={portal}
        sharedEntityType={sharedEntityType}
        type={PortalTypes.THING_TYPE}
      />
    );
  }

  if (entityType && !sharedEntityType) {
    return (
      <FetchOneOfAll
        dataType={CacheDataTypes.SHARED_THING_TYPE}
        id={entityType.sharedThingTypeId}
        renderFactory={(sharedThingType: SharedThingType) => {
          return (
            <BasicEntityCard
              entity={entity}
              entityType={entityType}
              portal={portal}
              sharedEntityType={sharedThingType}
              type={PortalTypes.THING_TYPE}
            />
          );
        }}
      />
    );
  }

  return (
    <FetchOneOfAll
      id={entity.thingTypeId}
      dataType={CacheDataTypes.THING_TYPE}
      renderFactory={(data: ThingType) => {
        if (!data.sharedThingTypeId) {
          return (
            <BasicEntityCard
              entity={entity}
              entityType={data}
              portal={portal}
              type={PortalTypes.THING_TYPE}
            />
          );
        }
        return (
          <FetchOneOfAll
            dataType={CacheDataTypes.SHARED_THING_TYPE}
            id={data.sharedThingTypeId}
            renderFactory={(sharedThingType: SharedThingType) => {
              return (
                <BasicEntityCard
                  entity={entity}
                  entityType={data}
                  portal={portal}
                  sharedEntityType={sharedThingType}
                  type={PortalTypes.THING_TYPE}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

const EditableCard: FC<EntityEditableCard<Thing, ThingType, SharedThingType>> = ({
  entity,
  entityType,
  error,
  handlePropertyChange,
  loadingButtonClass,
  portal,
  readonly,
  saveInProgress,
  sharedEntityType,
  updateEntity,
}) => {
  const { t } = useTranslation(['admin', 'common']);
  const { hasAuthorization } = useHasAuthorization();

  const hasViewPermissions = hasAuthorization(VIEW_ROLES);
  const hasThingAdminPermission = hasAuthorization(THING_ADMINISTRATOR);
  const hasEditPermissions = hasThingAdminPermission;
  const hasDisplayClientIdsFlag = useBoolFlag('portals-thing-detail-display-client-ids-object');
  const displayClientIds = hasThingAdminPermission && hasDisplayClientIdsFlag;

  const managementPermissions = (node: JSX.Element): JSX.Element => {
    if (portal !== Portals.ADMIN || hasViewPermissions) return node;
    return <></>;
  };

  return (
    <Stack spacing={2}>
      {managementPermissions(
        <>
          <TextField
            data-testid="id-input"
            disabled
            label={t('common:common.labels.id')}
            size="small"
            value={entity._id}
          />
          <TextEditor
            data-testid="display-input"
            disabled={saveInProgress || !hasEditPermissions || readonly}
            label={t('common:common.labels.display')}
            onChange={handlePropertyChange('display')}
            required
            size="small"
            value={entity.display}
          />
        </>,
      )}
      {(sharedEntityType?.properties && entity.sharedThingId)
        && <SharedThingPropertiesEditor
          sharedThingId={entity.sharedThingId}
          disabled={!hasEditPermissions || saveInProgress || readonly}
          onChange={handlePropertyChange('sharedProperties')}
          properties={entity.sharedProperties}
          propertyDefinitions={sharedEntityType.properties}
          sharedThingTypeId={sharedEntityType._id}
        />
      }
      {entityType.properties
        && <PropertiesElement
          disabled={!hasEditPermissions || saveInProgress || readonly}
          onChange={handlePropertyChange('properties')}
          properties={entity.properties}
          propertyDefinitions={entityType.properties}
          size="small"
        />
      }
      {managementPermissions(
        <TagsEditor
          disabled={!hasEditPermissions || saveInProgress || readonly}
          label={t('common:terms.tag')}
          onChange={handlePropertyChange('tags')}
          placeholder={t('common:common.hint.tag')}
          size="small"
          tags={entity.tags}
        />,
      )}
      {displayClientIds
        && <>
          <Typography variant="h6">{t('common:terms.client-ids')}</Typography>
          <LabelsEditor
            disabled={!hasEditPermissions || saveInProgress || readonly}
            labels={entity.clientIds}
            onChange={handlePropertyChange('clientIds')}
            placeholder={t('common:common.hint.client-ids-key')}
            size="small"
            textfieldLabel={t('common:common.labels.id')}
          />
        </>
      }
      {managementPermissions(
        <>
          <Typography variant="h6">{t('common:terms.label')}</Typography>
          <LabelsEditor
            disabled={!hasEditPermissions || saveInProgress || readonly}
            labels={entity.labels}
            onChange={handlePropertyChange('labels')}
            placeholder={t('common:common.hint.label')}
            size="small"
            textfieldLabel={t('common:terms.label')}
          />
        </>,
      )}
      {error
        && <Alert severity="error">
          {error}
        </Alert>
      }
      {hasEditPermissions
        && <LoadingButton
          className={loadingButtonClass}
          data-testid="save-button"
          disabled={readonly}
          loadingCaption={t('common:common.hint.saving')}
          sx={{ alignSelf: 'end', minWidth: 120 }}
          task={updateEntity}
        >
          {t('common:common.action.save')}
        </LoadingButton>
      }
    </Stack>
  );
};

const AdminCard: FC<EntityCardProps<Thing, ThingType, SharedThingType>> = ({ entityType, sharedEntityType, ...props }) => {
  if (entityType && (sharedEntityType || !entityType.sharedThingTypeId)) {
    return <EditableCard {...props as Required<EntityEditableCard<Thing, ThingType>>} entityType={entityType} sharedEntityType={sharedEntityType} />;
  }

  if (entityType && !sharedEntityType) {
    return (
      <FetchOneOfAll
        dataType={CacheDataTypes.SHARED_THING_TYPE}
        id={entityType.sharedThingTypeId}
        renderFactory={(sharedThingType: SharedThingType) => {
          return (
            <EditableCard {...props as Required<EntityEditableCard<Thing, ThingType>>} entityType={entityType} sharedEntityType={sharedThingType} />
          );
        }}
      />
    );
  }

  return (
    <FetchOneOfAll
      dataType={CacheDataTypes.THING_TYPE}
      id={props.entity.thingTypeId}
      renderFactory={(data: ThingType) => {
        if (!data.sharedThingTypeId) {
          return <EditableCard {...props as Required<EntityEditableCard<Thing, ThingType>>} entityType={data} />;
        }

        return (
          <FetchOneOfAll
            dataType={CacheDataTypes.SHARED_THING_TYPE}
            id={data.sharedThingTypeId}
            renderFactory={(sharedThingType: SharedThingType) => {
              return (
                <EditableCard {...props as Required<EntityEditableCard<Thing, ThingType>>} entityType={data} sharedEntityType={sharedThingType} />
              );
            }}
          />
        );
      }}
    />
  );
};

export const ThingCard: FC<EntityCardProps<Thing, ThingType, SharedThingType>> = ({ portal, editable, ...props }) => {
  if (editable) {
    return (
      <Card data-testid="thing-general-detail-panel">
        <PortalSwitchingDropdown entity={props.entity} portal={portal} type={PortalTypes.THING_TYPE} />
        <CardContent>
          <AdminCard {...props as EntityEditableCard<Thing, ThingType>} portal={portal} />
        </CardContent>
      </Card>
    );
  }
  return (
    <Card data-testid="thing-general-detail-panel">
      <NormalCard {...props} portal={portal} />
    </Card>
  );
};

export const PersonThingCard: FC<{ person: Person; portal: Portals; 'data-testid'?: string }> = ({ person, portal, ...props }) => {
  const { restClient } = useAuthenticated();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation(['common', 'terms']);

  const [personThings, personThingsError, personThingsState] = usePromise<PersonThingRangeResponse[]>(
    async () => {
      if (!person) return [];
      return restClient.personThing.getCurrentRangesByPerson(person._id);
    },
    [restClient, person],
  );

  if (personThingsError) return <ErrorMessage error={personThingsError} />;
  if (personThingsState === 'pending') return <MiddleSpinner />;
  if (!personThings?.length) return <></>;

  return (
    <Card data-testid={props['data-testid']}>
      <FetchOne
        id={personThings[0].thingId}
        dataType={CacheDataTypes.THING}
        renderFactory={(thing: Thing) => (
          <PortalSwitchingDropdown
            entity={thing}
            portal={portal}
            title={t('terms:thing', { count: personThings.length })}
            type={PortalTypes.THING_TYPE}
          />
        )}
      />
      <CardContent>
        <Stack spacing={2}>
          {personThings.map((personThing, i: number) => {
            if (i !== 0 && !expanded) return <></>;
            return <Stack key={i}>
              {i !== 0 && <Divider sx={{ margin: '16px 0px 12px 0px' }} />}
              <PersonThingDetail personThing={personThing} />
            </Stack>;
          })}
        </Stack>
        {(personThings.length > 1)
          && <Button
            onClick={() => setExpanded(!expanded)}
            size="small"
            sx={{ mt: 2, padding: 0 }}
          >
            {expanded ? t('common:common.action.hideOthers') : t('common:common.labels.othersWithCount', { count: personThings.length - 1 })}
          </Button>
        }
      </CardContent>
    </Card>
  );
};

const SharedThingPropertiesEditor: FC<PropertiesElementProps & { sharedThingId: string }> = ({ sharedThingId, disabled, propertyDefinitions, ...props }) => {
  const sharedThingCache = useFetchOneCache(CacheDataTypes.SHARED_THING);
  const { account } = useAuthenticated();

  const [sharedThing, error, status] = usePromise(() => {
    return sharedThingCache.one<SharedThing>(sharedThingId);
  }, [sharedThingCache, sharedThingId]);

  if (status === 'pending') {
    return <MiddleSpinner />;
  }

  if (propertyDefinitions.order.length === 0 || error) {
    return null;
  }

  const canEdit = Boolean(sharedThing?.ownerAccountIds.includes(account._id));

  return (
    <PropertiesElement
      disabled={disabled || !canEdit}
      propertyDefinitions={propertyDefinitions}
      size="small"
      {...props}
    />
  );
};
