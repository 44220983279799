import { Tooltip, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { TooltipPlacement } from './date-time-tooltip.types';

interface Props {
  'data-testid'?: string;
  disableHoverListener?: boolean;
  display: JSX.Element | string;
  placement?: TooltipPlacement;
  variant?: Variant;
  title: JSX.Element | string;
  sx?: SxProps;
}

export const DateTimeTooltip: FC<Props> = ({ disableHoverListener, display, placement, variant, title, sx, ...props }) => {
  return (
    <Tooltip
      arrow
      disableHoverListener={disableHoverListener}
      enterTouchDelay={0}
      placement={placement}
      title={title}
    >
      <Typography
        noWrap
        data-testid={props['data-testid'] || 'datetime-with-tooltip'}
        data-chromatic="ignore"
        sx={{ textDecoration: disableHoverListener ? 'none' : 'underline dotted', ...sx }}
        variant={variant}
      >
        {display}
      </Typography>
    </Tooltip>
  );
};
