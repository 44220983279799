import { FC } from 'react';
import { TileLayer } from 'react-leaflet';
import { HERE_MAP_API_KEY } from '../../../constants';
import { useConfig } from '../../../hooks/use-config';

interface TransportLayerProps {
  format?: 'png' | 'png8';
  size?: 256 | 512;
}

export const TransportLayer: FC<TransportLayerProps> = ({
  size = 256,
  format = 'png',
}) => {
  const config = useConfig();
  const apiKey = config?.hereMaps?.apiKey ?? HERE_MAP_API_KEY;

  return <TileLayer
    pane='overlayPane'
    url={`https://maps.hereapi.com/v3/blank/mc/{z}/{x}/{y}/${format}?features=vehicle_restrictions:active_and_inactive&apiKey=${apiKey}&size=${size}`}
    zIndex={3}
  />;
};
