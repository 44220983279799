import { SvgIcon } from '@mui/material';
import { FC, ReactNode } from 'react';
import { fadeIn } from './styles';

interface DynamicIconProps {
  'data-testid'?: string;
  icon: ReactNode | undefined;
  size?: string;
  color?: string;
}

export const DynamicIcon: FC<DynamicIconProps> = ({ icon, size = '1em', color = 'currentColor', ...props }) => {
  return icon ? <SvgIcon data-testid={props['data-testid']} width={size} sx={{ ...fadeIn, fontSize: size, color }}>{icon}</SvgIcon> : <></>;
};
