import { Operator } from '@jexop/core';
import { isString } from 'lodash';
import { ComponentType, FC, ReactNode } from 'react';
import { Nullable } from '../types';

import * as Widgets from '../components/widgets';

export const ui: Operator<ReactNode> = (props, { _id }): Nullable<ReactNode> => {
  const { name, ...rest } = props;

  if (!isString(name)) return null;

  const Widget = (Widgets as Record<string, ComponentType<unknown> | FC<unknown>>)[name];

  const widgetNameTestId = name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

  const testId = rest['data-testid'] || (isString(_id) ? `${widgetNameTestId}-${_id}` : widgetNameTestId);

  if (!Widget) return null;
  return <Widget {...rest} data-testid={testId} />;
};
