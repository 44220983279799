import { Skeleton } from '@mui/material';
import { isNumber } from 'lodash';
import { FC } from 'react';
import { mapWeatherIcon, useLocationWeatherQuery } from '../../util';
import { Icon } from '../icons/widget-icon';

interface Props {
  'data-testid'?: string;
  latLng: L.LatLng;
}

export const CurrentWeatherIcon: FC<Props> = ({ latLng, ...props }) => {
  const { isLoading, data: weather } = useLocationWeatherQuery({ latLng });

  if (isLoading) {
    return <Skeleton variant='circular' width='1em' height='1em' sx={{ display: 'inline-block' }} />;
  }

  const iconId = (weather && isNumber(weather.iconId)) ? weather.iconId : 0;
  const icon = mapWeatherIcon(iconId);

  return <Icon data-testid={props['data-testid']} icon={icon} />;
};
