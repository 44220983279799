import { MarkerType } from '@eagle/api-types';
import { useTheme } from '@mui/material';
import L from 'leaflet';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Marker } from 'react-leaflet';
import { getMarkerTemplate } from '../../util';

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  markerType?: MarkerType;
  background?: string;
  position: L.LatLngExpression;
  indicator?: string;
  icon?: ReactNode | string;
  image?: string;
}

export const InlayMarker: FC<Props> = ({ children, markerType, position, icon, image, background, indicator, ...props }) => {
  const theme = useTheme();
  const markerBackground = background || theme.marker.background;
  return <Marker data-testid={props['data-testid']} icon={getMarkerTemplate(markerType, icon, image, markerBackground, 'currentcolor', indicator)} position={position}>{children}</Marker>;
};
