/* eslint-disable react-hooks/exhaustive-deps */
import { AccessToken, Time, TokenPayload } from '@eagle/common';
import { OidcAuthRequest } from '@eagle/core-data-types';
import { useQueryClient } from '@tanstack/react-query';
import { sha256 } from 'js-sha256';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ulid } from 'ulid';
import { FILTERS_KEY } from '../components';
import { MAX_ACCOUNTS } from '../components/avatar/account-switcher';
import { MiddleSpinner } from '../components/middle-spinner';
import { useConfig } from '../hooks/use-config';
import { Undefinable } from '../types';
import { useLocalStorage } from '../util';
import { ClientTokenProvider, decomposeJwt, SwitchedClientTokenProvider } from './token';
import { Auth, AuthenticatedState, AuthenticationState, AuthorizeParamsOverride, AuthStatus, OpenIdConfiguration, SessionStorageKeys } from './types';
import { base64URLEncode } from './util';

const RECENT_ACCOUNTS_KEY = 'account-switcher-recent-accounts';

export const authContext = createContext<Auth>({
  config: undefined,
  createAuthorizeParams: () => null,
  getAccessToken: () => undefined,
  recentAccounts: [],
  signIn: async () => { },
  signOut: async () => { },
  switchAccount: async () => { },
  switchBackToOriginalAccount: () => { },
  state: undefined,
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth: FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvideAuth();

  if (!auth.config) {
    return (<MiddleSpinner />);
  }

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): Auth => {
  const context = useContext(authContext);
  const state: Undefinable<AuthenticationState> = context.state?.status === AuthStatus.AUTHENTICATED
    ? { ...context.state, axios: context.state.switchedTokenProvider?.axios() ?? context.state.axios }
    : context.state;
  return {
    ...context,
    state,
  };
};

// Hook for child components to get the auth object throwing exception if not authenticated ...
// ... and re-render when it changes.
export const useAuthenticated = (): AuthenticatedState => {
  const context = useContext(authContext);
  if (context.state?.status !== AuthStatus.AUTHENTICATED) throw new Error('Not authenticated');
  return context.state;
};

// Provider hook that creates auth object and handles state
// https://usehooks.com/useAuth/
const useProvideAuth = (): Auth => {
  const [auth, setAuth] = useState<AuthenticationState>();
  const [config, setConfig] = useState<OpenIdConfiguration>();
  const [tokenProvider, setTokenProvider] = useState<ClientTokenProvider>();
  const [switchedTokenProvider, setSwitchedTokenProvider] = useState<SwitchedClientTokenProvider>();
  const [recentAccounts, setRecentAccounts] = useLocalStorage<string[]>(RECENT_ACCOUNTS_KEY, []);
  const [targetAccountId, setTargetAccountId] = useState(window.sessionStorage.getItem(SessionStorageKeys.SWITCHED_ACCOUNT_ID));
  const queryClient = useQueryClient();

  const { auth: authConfig } = useConfig();

  useEffect(() => {
    const provider = new ClientTokenProvider({
      authSubject: 'client',
      thresholdBeforeExpire: Time.seconds(5),
      logger: {
        ...console,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        verbose: (...args: any[]) => console.debug(...args),
      },
      decodeToken: <T extends TokenPayload>(raw: string) => decomposeJwt(raw) as T,
      setAuth: (authenticationState?: AuthenticationState) => setAuth(authenticationState),
    });
    setTokenProvider(provider);

    return () => {
      provider.cleanup();
      switchedTokenProvider?.cleanup();
      setTokenProvider(undefined);
      setSwitchedTokenProvider(undefined);
    };
  }, []);

  // load configuration
  useEffect(() => {
    if (!tokenProvider || !authConfig) return;

    tokenProvider.fetchOidcConfig(authConfig).then(setConfig).catch(console.error);
  }, [authConfig, tokenProvider]);

  useEffect(() => {
    if (!auth) return;

    if (auth.status !== AuthStatus.AUTHENTICATED) return;
    const targetAccountId = window.sessionStorage.getItem(SessionStorageKeys.SWITCHED_ACCOUNT_ID);
    if (targetAccountId && !auth.switchedTokenProvider) {
      switchAccount(targetAccountId).then().catch((err: Error) => { throw err; });
    }
  }, [auth]);

  const createAndSaveCodeVerifier = (): string => {
    const bytes = new Uint8Array(32);
    window.crypto.getRandomValues(bytes);
    const codeVerifier = base64URLEncode(Array.from(bytes));
    window.localStorage.setItem('code-verifier', codeVerifier);
    return codeVerifier;
  };

  const createAuthorizeParams = (overrides?: AuthorizeParamsOverride): OidcAuthRequest | null => {
    if (!config) {
      return null;
    }

    const scope = Array.from(new Set([
      'email',
      'openid',
      'profile',
      ...config.additionalScope ?? [],
    ]));

    let codeVerifier = window.localStorage.getItem('code-verifier');
    if (!codeVerifier) {
      codeVerifier = createAndSaveCodeVerifier();
    }
    const codeChallenge = base64URLEncode(sha256.digest(codeVerifier));

    const params: OidcAuthRequest = {
      'client_id': config.clientId,
      'code_challenge': codeChallenge,
      'code_challenge_method': 'S256',
      'domain_hint': config.domainHint ?? document.location.host,
      'redirect_uri': overrides?.redirectUri ?? document.location.href.split('#')[0],
      'response_mode': 'fragment',
      'response_type': 'code',
      'scope': scope.join(' '),
      'nonce': ulid(),
      'state': overrides?.state ?? window.btoa(JSON.stringify({ hash: document.location.hash })),
    };

    return params;
  };

  const signIn = (overrides?: AuthorizeParamsOverride): Promise<void> => {
    if (!config) return Promise.resolve();

    createAndSaveCodeVerifier();

    const params = createAuthorizeParams(overrides);

    if (params === null) {
      return Promise.resolve();
    }

    const url = new URL(config.authUrl);
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value as string);
    }

    document.location.href = url.toString();

    return Promise.resolve();
  };

  const signOut = async (signInParam?: string): Promise<void> => {
    if (!tokenProvider) throw new Error('Sign out happened before sign in');

    window.localStorage.removeItem('code-verifier');
    window.sessionStorage.removeItem(SessionStorageKeys.SWITCHED_ACCOUNT_ID);
    queryClient.clear();
    await tokenProvider.signOut(config?.signOutUrl, signInParam);
  };

  const switchAccount = async (targetAccountId: string): Promise<void> => {
    if (!tokenProvider) return;

    const result = await tokenProvider.axios().post<{ accessToken: string }>(`/api/v1/token/user/switch/${targetAccountId}`);

    const provider = new SwitchedClientTokenProvider({
      authSubject: 'switched-client',
      thresholdBeforeExpire: Time.seconds(5),
      logger: {
        ...console,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        verbose: (...args: any[]) => console.debug(...args),
      },
      decodeToken: <T extends TokenPayload>(raw: string) => decomposeJwt(raw) as T,
      setAuth: (authenticationState?: AuthenticationState) => setAuth(authenticationState),
      accountId: targetAccountId,
    });

    setRecentAccounts((accounts) => {
      const newValue = accounts.filter((account) => account !== targetAccountId);
      return [targetAccountId, ...newValue].splice(0, MAX_ACCOUNTS);
    });

    setTargetAccountId(targetAccountId);

    window.sessionStorage.setItem(SessionStorageKeys.SWITCHED_ACCOUNT_ID, targetAccountId);
    provider.setAccessToken(result.data.accessToken);
    setSwitchedTokenProvider(provider);
    queryClient.clear();
  };

  const switchBackToOriginalAccount = (): void => {
    queryClient.clear();
    window.sessionStorage.removeItem(SessionStorageKeys.SWITCHED_ACCOUNT_ID);
    window.localStorage.setItem(FILTERS_KEY, JSON.stringify([]));
    window.location.reload();
  };

  const getAccessToken = (): Undefinable<AccessToken> => {
    if (switchedTokenProvider) {
      return switchedTokenProvider.getAccessToken();
    }
    return tokenProvider?.getAccessToken();
  };

  const isSwitchingInProgress = Boolean(auth?.status === AuthStatus.AUTHENTICATED && targetAccountId && auth.account._id !== targetAccountId);

  // Return the user object and auth methods
  return {
    config,
    createAuthorizeParams,
    getAccessToken,
    recentAccounts,
    signIn,
    signOut,
    switchAccount,
    switchBackToOriginalAccount,
    state: isSwitchingInProgress ? { status: AuthStatus.LOADING } : auth,
  };
};
