import { styled, Tooltip, useTheme } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface MapOverlayProps extends PropsWithChildren {
  'data-testid'?: string;
  tooltip?: ReactNode;
}

export const MapOverlay: FC<MapOverlayProps> = ({ children, tooltip, ...props }) => {
  const theme = useTheme();

  const Overlay = styled('div')({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0 0 8px 8px',
    gap: `0 ${theme.spacing(2)}`,
    width: 'fit-content',
    boxShadow: theme.shadows[2],
    height: '60px',
    overflow: 'hidden',
    '& > div': {
      height: '60px',
    },
  });

  if (tooltip) {
    return <Tooltip arrow title={tooltip}>
      <Overlay data-testid={props['data-testid']}>
        {children}
      </Overlay>
    </Tooltip>;
  }

  return <Overlay data-testid={props['data-testid']}>
    {children}
  </Overlay>;
};
