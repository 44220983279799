import CloseIcon from '@mui/icons-material/Close';
import { Box, Breakpoint, Collapse, Dialog as MuiDialog, DialogContent as MuiDialogContent, DialogProps as MuiDialogProps, Divider, IconButton, Slide, Stack, Typography, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { SxProps } from '@mui/system';
import { FC, forwardRef, PropsWithChildren, ReactNode } from 'react';
import { useSmallScreen } from '../../hooks';

interface Props extends PropsWithChildren {
  actions?: React.ReactNode;
  'data-testid'?: string;
  disableCloseOnBackdropClick?: boolean;
  disableEscKey?: boolean;
  fullWidth?: boolean;
  hasCloseBtn?: boolean;
  maxWidth?: Breakpoint;
  onClose: () => void;
  open: boolean;
  scroll?: boolean;
  subtitle?: string;
  title?: string;
}

type DialogCloseReasons = 'escapeKeyDown' | 'backdropClick';

const Transition = (
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
): JSX.Element => {
  return <Slide direction="up" ref={ref} {...props} />;
};
const TransitionComponent = forwardRef(Transition);

export const Dialog: FC<Props> = ({
  actions,
  children,
  disableCloseOnBackdropClick,
  disableEscKey,
  fullWidth,
  hasCloseBtn,
  maxWidth,
  onClose,
  open,
  scroll,
  subtitle,
  title,
  ...props
}) => {
  const handleClose = (reason: DialogCloseReasons): void => {
    if (disableCloseOnBackdropClick && reason === 'backdropClick') return;
    onClose();
  };

  return <DialogRoot
    data-testid={props['data-testid']}
    disableEscapeKeyDown={disableEscKey}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    onClose={(_, reason) => handleClose(reason)}
    open={open}
    scroll={scroll ? 'paper' : undefined}
  >
    {hasCloseBtn && <DialogCloseButton onClick={onClose} />}
    <DialogInner>
      {title && <DialogTitle title={title} subtitle={subtitle} />}
      {children && <DialogContent>{children}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </DialogInner>
  </DialogRoot>;
};

interface DialogRootProps extends MuiDialogProps {
  'data-testid'?: string;
}

export const DialogRoot: FC<DialogRootProps> = (props) => {
  return (
    <MuiDialog
      sx={{ maxHeight: '55vh', height: 'max-content', marginTop: 0, '& .MuiDialog-container': { height: 'auto' } }}
      TransitionComponent={TransitionComponent}
      {...props}
    />
  );
};

interface DialogCloseButtonProps {
  onClick: VoidFunction;
}

export const DialogCloseButton: FC<DialogCloseButtonProps> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <IconButton
      aria-label="close"
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

interface DialogInnerProps extends PropsWithChildren {
  sx?: SxProps;
}

export const DialogInner: FC<DialogInnerProps> = (props) => {
  return (
    <Stack
      direction='column'
      divider={<Divider orientation='horizontal' flexItem />}
      {...props}
    />
  );
};

interface DialogTitleProps {
  title: ReactNode;
  subtitle?: ReactNode;
}

export const DialogTitle: FC<DialogTitleProps> = ({ title, subtitle }) => {
  const smallScreen = useSmallScreen();

  return (
    <Stack spacing={1} sx={{ m: 2 }}>
      <Typography
        data-testid="title"
        sx={{ fontWeight: 'bold' }}
        variant={smallScreen ? 'body2' : 'body1'}
      >
        {title}
      </Typography>
      <Collapse in={!!subtitle}>
        <Typography
          data-testid="sub-title"
          variant={smallScreen ? 'body2' : 'body1'}
        >
          {subtitle}
        </Typography>
      </Collapse>
    </Stack>
  );
};

interface DialogContentProps extends PropsWithChildren {
  scroll?: boolean;
}

export const DialogContent: FC<DialogContentProps> = ({ children, scroll }) => {
  return (
    <MuiDialogContent
      data-testid="content"
      sx={{ p: 2, m: 0, maxHeight: scroll ? '80vh' : 'inherit' }}
    >
      {children}
    </MuiDialogContent>
  );
};

export const DialogActions: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
      <Stack direction='row' spacing={2}>{children}</Stack>
    </Box>
  );
};
