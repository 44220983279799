import { Thing, ThingType } from '@eagle/core-data-types';
import { Avatar, Link, styled } from '@mui/material';
import { FC } from 'react';
import { useHref } from 'react-router-dom';
import { useCustomRoutes } from '../../../hooks';
import { CacheDataTypes } from '../../../types';
import { FetchOne, FetchOneOfAll } from '../../fetch';
import { Icon } from '../../icons/widget-icon';
import { DataCard } from '../data-card';

interface Props {
  'data-testid'?: string;
  id?: string;
  thing?: Thing;
  thingType?: ThingType;
  disableLink?: boolean;
  wrap?: boolean;
}

const TransparentAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.dataCard.contrastText,
}));

const DefaultAvatar: FC = () => {
  return <TransparentAvatar><Icon icon='ThingIcon' /></TransparentAvatar>;
};

const ThingTypeDisplay: FC<{ id: string }> = ({ id }) => {
  return <FetchOneOfAll dataType={CacheDataTypes.THING_TYPE} id={id} notFoundFactory={() => <></>} renderFactory={(thingType: ThingType) => {
    return <>{thingType.display}</>;
  }} />;
};

const ThingTypeAvatar: FC<{ thingType: ThingType }> = ({ thingType }) => {
  if (thingType.image?.filename) {
    return <TransparentAvatar src={thingType.image.filename} />;
  }

  if (thingType.icon) {
    return <TransparentAvatar><Icon icon={thingType.icon} /></TransparentAvatar>;
  }

  return <DefaultAvatar />;
};

const ThingAvatar: FC<{ thing: Thing; thingType?: ThingType }> = ({ thing, thingType }) => {
  if (thing.image?.filename) {
    return <TransparentAvatar src={thing.image.filename} />;
  }

  if (thing.icon) {
    return <TransparentAvatar><Icon icon={thing.icon} /></TransparentAvatar>;
  }

  if (thingType) {
    return <ThingTypeAvatar thingType={thingType} />;
  }

  return <FetchOneOfAll dataType={CacheDataTypes.THING_TYPE} id={thing.thingTypeId} notFoundFactory={() => <DefaultAvatar />} renderFactory={(thingType: ThingType) => {
    return <ThingTypeAvatar thingType={thingType} />;
  }} />;
};

const ThingLink: FC<{ title: string; id: string }> = ({ title, id }) => {
  const { thing: thingRoute } = useCustomRoutes();
  const href = useHref(`/${thingRoute}/${id}`);
  return <Link href={href}>{title}</Link>;
};

export const ThingDataCard: FC<Props> = ({ id, thing, thingType, disableLink, wrap, ...props }) => {
  if (id) {
    return <FetchOne
      dataType={CacheDataTypes.THING}
      id={id}
      loadingFactory={() => <DefaultAvatar />}
      renderFactory={(thing: Thing) => {
        return <DataCard data-testid={props['data-testid']} wrap={wrap} title={!disableLink ? <ThingLink id={id} title={thing.display} /> : thing.display} label={thingType ? <>{thingType.display}</> : <ThingTypeDisplay id={thing.thingTypeId} />} avatar={<ThingAvatar thing={thing} thingType={thingType} />} />;
      }}
    />;
  }

  if (thing) {
    return <DataCard data-testid={props['data-testid']} wrap={wrap} title={!disableLink ? <ThingLink id={thing._id} title={thing.display} /> : thing.display} label={thingType ? <>{thingType.display}</> : <ThingTypeDisplay id={thing.thingTypeId} />} avatar={<ThingAvatar thing={thing} thingType={thingType} />} />;
  }

  return <></>;
};
