import { Box, Fade as MuiFade } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';

interface FadeProps {
  'data-testid'?: string;
  firstNode: ReactNode;
  secondNode: ReactNode;
  duration?: number;
}

export const Fade: FC<FadeProps> = ({ firstNode, secondNode, duration = 1000, ...props }) => {
  const [showFirst, setShowFirst] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirst((prevShowFirst) => !prevShowFirst);
    }, duration * 2);
    return () => clearInterval(interval);
  }, [duration]);

  return (
    <Box data-testid={props['data-testid']} sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <MuiFade in={showFirst} timeout={duration}>
        <Box sx={{ position: showFirst ? 'relative' : 'absolute', width: '100%', height: '100%' }}>
          {firstNode}
        </Box>
      </MuiFade>
      <MuiFade in={!showFirst} timeout={duration}>
        <Box sx={{ position: !showFirst ? 'relative' : 'absolute', width: '100%', height: '100%' }}>
          {secondNode}
        </Box>
      </MuiFade>
    </Box>
  );
};
