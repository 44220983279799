import { Time } from '@eagle/common';
import { Box, ClickAwayListener, Tooltip, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../error-message';
import { getThingEventServerTime } from '../events';
import { FormatTimestamp } from '../format/format-timestamp';
import { DynamicIcon, LastContactIcon } from '../icons';
import { LastContactProps } from './last-contact.types';

const CURRENTLY_ONLINE = Time.minutes(5);
const RECENTLY_ONLINE = Time.minutes(30);

export const LastContact: FC<LastContactProps> = ({
  icon = <LastContactIcon />,
  iconSize = 'large',
  lastThingError,
  lastThingEvent,
  sx,
  tooltipSx,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const [showTooltip, setShowTooltip] = useState(false);

  if (lastThingError) return <ErrorMessage error={lastThingError} />;
  if (!lastThingEvent) return <></>;

  const timeReceived = getThingEventServerTime(lastThingEvent);
  const millisecondsDiff = DateTime.utc().toLocal().diff(DateTime.fromJSDate(timeReceived).toLocal(), 'milliseconds').milliseconds;

  const color = millisecondsDiff < CURRENTLY_ONLINE
    ? theme.palette.success.light
    : millisecondsDiff < RECENTLY_ONLINE
      ? theme.palette.warning.light
      : theme.palette.grey[600];

  const handleTooltipClose = (): void => {
    setShowTooltip(false);
  };

  const handleTooltipOpen = (): void => {
    setShowTooltip(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'none !important',
              ...tooltipSx,
            },
          },
        }}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        onTouchStartCapture={handleTooltipOpen}
        open={showTooltip}
        placement="bottom"
        title={<Typography variant="caption" width="max-content">{t('common:component.last-contact.labels.contact-time')} <FormatTimestamp value={timeReceived} /></Typography>}
      >
        <Box
          data-testid={props['data-testid']}
          flexDirection="column"
          component="span"
          sx={{
            display: 'flex',
            width: 'fit-content',
            ...sx,
          }}
        >
          <DynamicIcon icon={icon} color={color} size={iconSize} />
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};
